export const restconfig = {
  url: `https://dispo-dev2.tixi24.ch/rest/private/v2/`,
  getpools: `getPools`,
  login: `register`,
  missions: `getMissions`,
  setorderstatus: 'setOrderStatus',
  setmissionstatus: 'setMissionStatus',
  uuid: '746e5199-71b3-4cea-ac4a-35688aa65356'
}

export const servers = {
  'Tixi Aargau': 'https://dispo.tixi-aargau.ch/rest/private/v2/',
  'Med Pegasus': `https://dispo.med-pt.ch/rest/private/v2/`,
  'FahrFlex': `https://dispo.fahrflex.ch/rest/private/v2/`
}

/*
export const servers = {
  'dev4': `https://dispo-dev4.tixi24.ch/rest/private/v2/`
}

*/



export const ApiKey =  'AIzaSyBCyR92eHYPx8iywjWYhMUVLcyJxbJe33k'

export const MapPopUp =  false

export const EndRideOverride =  true


